import { combineReducers } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';

import authReducer from './modules/auth';
import accountsReducer from './modules/accounts';
import alertsReducer from './modules/alerts';
import masterLogsReducer from './modules/masterLogs';
import alertTypesReducer from './modules/alertTypes';
import notifyCenter from './modules/notifyCenter';
import endUserKeysReducer from './modules/endUserKeys';
import endUserDevicesReducer from './modules/endUserDevices';
import endUserGroupsReducer from './modules/endUserGroups';
import lockersReducer from './modules/lockers';
import messageLogsReducer from './modules/messageLogs';
import messagesReducer from './modules/messages';
import networkConfigurationsReducer from './modules/networkConfigurations';
import modemConfigReducer from './modules/modemConfigs';
import endUsersReducer from './modules/endUsers';
import customersReducer from './modules/customers';
import accessRequestsReducer from './modules/masterAccessRequest';
import masterConfigurationsReducer from './modules/masterConfiguration';
import masterConfigurationLogsReducer from './modules/masterConfigurationLog';
import mplsReducer from './modules/mpl';
import groupsReducer from './modules/groups';
import languageReducer from './modules/language';
import epdUnitsReducer from './modules/epdUnits';
import groupPinsReducer from './modules/groupPins';
import couriersReducer from './modules/couriers';
import collectorReducer from './modules/collector';
import collectorAddressReducer from './modules/collectorAddress';
import courierDeviceReducer from './modules/courierDevice';
import mailCodesReducer from './modules/mailCodes';

export default combineReducers({
  toastr,
  auth: authReducer,
  accounts: accountsReducer,
  alerts: alertsReducer,
  masterLogs: masterLogsReducer,
  alertTypes: alertTypesReducer,
  endUserKeys: endUserKeysReducer,
  endUserDevices: endUserDevicesReducer,
  endUserGroups: endUserGroupsReducer,
  endUsers: endUsersReducer,
  messageLogs: messageLogsReducer,
  messages: messagesReducer,
  modemConfigs: modemConfigReducer,
  lockers: lockersReducer,
  accessRequests: accessRequestsReducer,
  masterConfigurations: masterConfigurationsReducer,
  masterConfigurationLogs: masterConfigurationLogsReducer,
  customers: customersReducer,
  networkConfigurations: networkConfigurationsReducer,
  mpls: mplsReducer,
  notifyCenter,
  groups: groupsReducer,
  languages: languageReducer,
  epdUnits: epdUnitsReducer,
  groupPins: groupPinsReducer,
  couriers: couriersReducer,
  collector: collectorReducer,
  collectorAddress: collectorAddressReducer,
  courierDevices: courierDeviceReducer,
  mailCodes: mailCodesReducer,
});
