import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS
export const END_USER_GROUP_FETCH_REQUEST =
  'endUsers/END_USER_GROUP_FETCH_REQUEST';
export const END_USER_GROUP_FETCH_SUCCESS =
  'endUsers/END_USER_GROUP_FETCH_SUCCESS';
export const END_USER_GROUP_FETCH_ERROR = 'endUsers/END_USER_GROUP_FETCH_ERROR';

export const END_USER_GROUP_GET_SAMPLE_REQUEST =
  'endUsers/END_USER_GROUP_GET_SAMPLE_REQUEST';
export const END_USER_GROUP_GET_SAMPLE_SUCCESS =
  'endUsers/END_USER_GROUP_GET_SAMPLE_SUCCESS';
export const END_USER_GROUP_GET_SAMPLE_ERROR =
  'endUsers/END_USER_GROUP_GET_SAMPLE_ERROR';

export const END_USER_GROUP_PARSE_CSV_REQUEST =
  'endUsers/END_USER_GROUP_PARSE_CSV_REQUEST';
export const END_USER_GROUP_PARSE_CSV_SUCCESS =
  'endUsers/END_USER_GROUP_PARSE_CSV_SUCCESS';
export const END_USER_GROUP_PARSE_CSV_ERROR =
  'endUsers/END_USER_GROUP_PARSE_CSV_ERROR';

export const END_USER_GROUP_CREATE_REQUEST =
  'endUsers/END_USER_GROUP_CREATE_REQUEST';
export const END_USER_GROUP_CREATE_SUCCESS =
  'endUsers/END_USER_GROUP_CREATE_SUCCESS';
export const END_USER_GROUP_CREATE_ERROR =
  'endUsers/END_USER_GROUP_CREATE_ERROR';

export const END_USER_GROUP_APPEND_REQUEST =
  'endUsers/END_USER_GROUP_APPEND_REQUEST';
export const END_USER_GROUP_APPEND_SUCCESS =
  'endUsers/END_USER_GROUP_APPEND_SUCCESS';
export const END_USER_GROUP_APPEND_ERROR =
  'endUsers/END_USER_GROUP_APPEND_ERROR';

export const END_USER_GROUP_CREATE_BUTTON_GROUP_REQUEST =
  'endUsers/END_USER_GROUP_CREATE_BUTTON_GROUP_REQUEST';
export const END_USER_GROUP_CREATE_BUTTON_GROUP_SUCCESS =
  'endUsers/END_USER_GROUP_CREATE_BUTTON_GROUP_SUCCESS';
export const END_USER_GROUP_CREATE_BUTTON_GROUP_ERROR =
  'endUsers/END_USER_GROUP_CREATE_BUTTON_GROUP_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
    importGroups: null,
    sample: null,
    fetchingSample: false,
    updating: false,
  },
  action
) {
  switch (action.type) {
    case END_USER_GROUP_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case END_USER_GROUP_GET_SAMPLE_REQUEST:
      return {
        ...state,
        fetchingSample: true,
        sample: null,
      };

    case END_USER_GROUP_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: false,
        entities: action.payload.entities.endUserGroups,
        result: action.payload.result,
      };
    case END_USER_GROUP_GET_SAMPLE_SUCCESS:
      return {
        ...state,
        sample: action.payload,
        fetchingSample: false,
      };
    case END_USER_GROUP_PARSE_CSV_SUCCESS:
      return {
        ...state,
        importGroups: action.payload,
      };

    case END_USER_GROUP_FETCH_ERROR:
    case END_USER_GROUP_PARSE_CSV_ERROR:
    case END_USER_GROUP_CREATE_ERROR:
    case END_USER_GROUP_APPEND_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
        importGroups: null,
      };
    case END_USER_GROUP_CREATE_REQUEST:
    case END_USER_GROUP_APPEND_REQUEST:
    case END_USER_GROUP_CREATE_BUTTON_GROUP_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case END_USER_GROUP_CREATE_SUCCESS:
    case END_USER_GROUP_APPEND_SUCCESS:
    case END_USER_GROUP_CREATE_BUTTON_GROUP_SUCCESS:
    case END_USER_GROUP_CREATE_BUTTON_GROUP_ERROR:
      return {
        ...state,
        updating: false,
        entities:
          action.payload && action.payload.id
            ? { ...state.entities, [action.payload.id]: action.payload }
            : state.entities,
        current: action.payload,
      };
    default:
      return state;
  }
}
// Schemas
const endUserGroupsSchema = new schema.Entity('endUserGroups');
// Operations
export function fetchEndUserGroup(params) {
  return {
    [CALL_API]: {
      types: [
        END_USER_GROUP_FETCH_REQUEST,
        END_USER_GROUP_FETCH_SUCCESS,
        END_USER_GROUP_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/groupMember', params),
      schema: { data: [endUserGroupsSchema] },
    },
  };
}

export function getSampleCSV(supportsDoorbell) {
  return {
    [CALL_API]: {
      types: [
        END_USER_GROUP_GET_SAMPLE_REQUEST,
        END_USER_GROUP_GET_SAMPLE_SUCCESS,
        END_USER_GROUP_GET_SAMPLE_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.get(
          supportsDoorbell
            ? '/endUser/import/sample/csv/includeDoorBellIndex'
            : '/endUser/import/sample/csv'
        ),
    },
  };
}

export function parseGroupCSV(data) {
  return {
    [CALL_API]: {
      types: [
        END_USER_GROUP_PARSE_CSV_REQUEST,
        END_USER_GROUP_PARSE_CSV_SUCCESS,
        END_USER_GROUP_PARSE_CSV_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.post('/endUser/import/parse/csv', data, {
          headers: { 'Content-Type': 'multipart/form-data' },
        }),
    },
  };
}

export function importGroupCSV(data) {
  return {
    [CALL_API]: {
      types: [
        END_USER_GROUP_CREATE_REQUEST,
        END_USER_GROUP_CREATE_SUCCESS,
        END_USER_GROUP_CREATE_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.post('/endUser/import/createNewGroup/csv', data, {
          headers: { 'Content-Type': 'multipart/form-data' },
        }),
      successMessage: 'New group successfully added',
    },
  };
}

export function importCSVGroupToLinuxLocker(data) {
  return {
    [CALL_API]: {
      types: [
        END_USER_GROUP_CREATE_REQUEST,
        END_USER_GROUP_CREATE_SUCCESS,
        END_USER_GROUP_CREATE_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.post('/endUser/import/device/createNewGroup/csv', data, {
          headers: { 'Content-Type': 'multipart/form-data' },
        }),
      successMessage: 'New users successfully added',
    },
  };
}

export function appendToGroup(data) {
  return {
    [CALL_API]: {
      types: [
        END_USER_GROUP_APPEND_REQUEST,
        END_USER_GROUP_APPEND_SUCCESS,
        END_USER_GROUP_APPEND_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.post('/endUser/import/toExistingGroup/csv', data, {
          headers: { 'Content-Type': 'multipart/form-data' },
        }),
      successMessage: 'Successfully appended to group',
    },
  };
}

export function createButtonGroup(params) {
  return {
    [CALL_API]: {
      types: [
        END_USER_GROUP_CREATE_BUTTON_GROUP_REQUEST,
        END_USER_GROUP_CREATE_BUTTON_GROUP_SUCCESS,
        END_USER_GROUP_CREATE_BUTTON_GROUP_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.post(
          '/endUser/import/mpl/createNewGroup/manualData',
          params
        ),
      successMessage: 'New group created and assigned to button',
    },
  };
}

export function createSingleGroup({
  group,
  successMessage,
  errorMessage,
  onSuccess,
}) {
  return {
    [CALL_API]: {
      types: [
        END_USER_GROUP_CREATE_REQUEST,
        END_USER_GROUP_CREATE_SUCCESS,
        END_USER_GROUP_CREATE_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/group/register', group),
      successMessage: successMessage || 'New group created',
      errorMessage: errorMessage || 'There was a problem creating the group',
      onSuccess: onSuccess || (() => null),
    },
  };
}
