import { schema } from 'normalizr';
import { CALL_API } from '../middleware/api';

// ACTIONS
export const ACCOUNT_FETCH_REQUEST = 'accounts/ACCOUNT_FETCH_REQUEST';
export const ACCOUNT_FETCH_SUCCESS = 'accounts/ACCOUNT_FETCHED_SUCCESS';
export const ACCOUNT_FETCH_ERROR = 'accounts/ACCOUNT_FETCHED_ERROR';

export const ACCOUNTS_FETCH_REQUEST = 'accounts/ACCOUNTS_FETCH_REQUEST';
export const ACCOUNTS_FETCH_SUCCESS = 'accounts/ACCOUNTS_FETCHED_SUCCESS';
export const ACCOUNTS_FETCH_ERROR = 'accounts/ACCOUNTS_FETCHED_ERROR';

export const ACCOUNT_CREATE_REQUEST = 'accounts/ACCOUNT_CREATE_REQUEST';
export const ACCOUNT_CREATE_SUCCESS = 'accounts/ACCOUNT_CREATE_SUCCESS';
export const ACCOUNT_CREATE_ERROR = 'accounts/ACCOUNT_CREATE_ERROR';

export const ACCOUNT_MODIFY_REQUEST = 'accounts/ACCOUNT_MODIFY_REQUEST';
export const ACCOUNT_MODIFY_SUCCESS = 'accounts/ACCOUNT_MODIFY_SUCCESS';
export const ACCOUNT_MODIFY_ERROR = 'accounts/ACCOUNT_MODIFY_ERROR';

export const ACCOUNT_UNLOAD_CURRENT = 'accounts/ACCOUNT_UNLOAD_CURRENT';

export const ACCOUNT_CHANGE_STATUS_REQUEST =
  'accounts/ACCOUNT_CHANGE_STATUS_REQUEST';
export const ACCOUNT_CHANGE_STATUS_SUCCESS =
  'accounts/ACCOUNT_CHANGE_STATUS_SUCCESS';
export const ACCOUNT_CHANGE_STATUS_ERROR =
  'accounts/ACCOUNT_CHANGE_STATUS_ERROR';

export const MODERATOR_FETCH_REQUEST = 'account/MODERATOR_FETCH_REQUEST';
export const MODERATOR_FETCH_SUCCESS = 'account/MODERATOR_FETCH_SUCCESS';
export const MODERATOR_FETCH_ERROR = 'account/MODERATOR_FETCH_ERROR';

// REDUCER
export default function reducer(
  state = {
    entities: null,
    result: [],
    current: null,
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case ACCOUNTS_FETCH_REQUEST:
    case ACCOUNT_FETCH_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ACCOUNTS_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        entities: action.payload.entities.accounts,
        result: action.payload.result,
      };
    case ACCOUNT_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        current: action.payload,
      };
    case ACCOUNTS_FETCH_ERROR:
    case ACCOUNT_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case ACCOUNT_UNLOAD_CURRENT:
      return {
        ...state,
        current: null,
      };
    case ACCOUNT_MODIFY_SUCCESS:
      return {
        ...state,
        current: action.payload,
      };
    case ACCOUNT_CHANGE_STATUS_SUCCESS: {
      const newCurrent = Object.assign({}, state.current);
      newCurrent.status = !action.status ? 'INACTIVE' : 'ACTIVE';

      const newEntities = Object.assign({}, state.entities);
      newEntities[action.accountId] = newCurrent;

      return {
        ...state,
        current: newCurrent,
        entities: newEntities,
      };
    }
    default:
      return state;
  }
}

// Schemas
const accountSchema = new schema.Entity('accounts');

// Operations
export function fetchAccounts(params) {
  return {
    [CALL_API]: {
      types: [
        ACCOUNTS_FETCH_REQUEST,
        ACCOUNTS_FETCH_SUCCESS,
        ACCOUNTS_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/account', params),
      schema: { data: [accountSchema] },
    },
  };
}

export function fetchAccount(id) {
  return function (dispatch, getState) {
    const accountsState = getState().accounts;

    if (accountsState.entities && id in accountsState.entities) {
      // account already exists in store
      dispatch({
        type: ACCOUNT_FETCH_SUCCESS,
        payload: accountsState.entities[id],
      });
    } else {
      dispatch({
        [CALL_API]: {
          types: [
            ACCOUNT_FETCH_REQUEST,
            ACCOUNT_FETCH_SUCCESS,
            ACCOUNT_FETCH_ERROR,
          ],
          promise: (httpClient) => httpClient.get(`/account/${id}`),
        },
      });
    }
  };
}

export function unloadCurrent() {
  return function (dispatch) {
    dispatch({ type: ACCOUNT_UNLOAD_CURRENT });
  };
}

export function createAccount(account) {
  return {
    [CALL_API]: {
      types: [
        ACCOUNT_CREATE_REQUEST,
        ACCOUNT_CREATE_SUCCESS,
        ACCOUNT_CREATE_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/account/modify', account),
      successMessage: 'Account successfully created',
    },
  };
}

export function modifyAccount(account) {
  return {
    [CALL_API]: {
      types: [
        ACCOUNT_MODIFY_REQUEST,
        ACCOUNT_MODIFY_SUCCESS,
        ACCOUNT_MODIFY_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/account/modify', account),
      successMessage: 'Account successfully modified',
    },
  };
}

export function changeActivationStatus(accountId, status) {
  return {
    accountId,
    status,
    [CALL_API]: {
      types: [
        ACCOUNT_CHANGE_STATUS_REQUEST,
        ACCOUNT_CHANGE_STATUS_SUCCESS,
        ACCOUNT_CHANGE_STATUS_ERROR,
      ],
      promise: (httpClient) =>
        httpClient.get(`/account/switchstatus/${accountId}/${status}`),
      successMessage: 'User status changed',
    },
  };
}

export function fetchModerators(params) {
  return {
    [CALL_API]: {
      types: [
        MODERATOR_FETCH_REQUEST,
        MODERATOR_FETCH_SUCCESS,
        MODERATOR_FETCH_ERROR,
      ],
      promise: (httpClient) => httpClient.post('/account', params),
      schema: { data: [accountSchema] },
    },
  };
}
